import Container from "react-bootstrap/Container";
import hero from "../assests/hero.jpg";
import { FaArrowRightLong } from "react-icons/fa6";
import nocn from "../assests/nocn.png";
import globe from "../assests/globe.png";
import testOfferings from "../assests/choose.png";
import success from "../assests/success.png";
import successfulLearner from "../assests/successfulLearner.png";
import Default from "../assests/successLearner.png";
import Carousel from "react-multi-carousel";
import trick from "../assests/tick.jpg";
import { questionAnswer } from "../data/question-answer";
import { useState } from "react";
import { window } from "browser-monads";
import rightArrow from "../assests/RightArrow.svg";
import { url_webapp } from "../config";
import news1 from "../assests/news1.png";
import news2 from "../assests/news2.png";
import news3 from "../assests/news3.png";
import InstagramPosts from "../components/social-media-post";
function Index() {
  const [visibleAnswers, setVisibleAnswers] = useState(
    Array(questionAnswer.length).fill(false)
  );

  const toggleAnswerVisibility = (index) => {
    setVisibleAnswers((prevVisibleAnswers) =>
      prevVisibleAnswers.map((visible, i) => (i === index ? !visible : visible))
    );
  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const carousalSuccess = [
    {
      imgUrl: Default,
      name: "Dikshit Kashyap",
      designation: "Recent Graduate",
      about:
        "SkillsTrainer ESOL was key to getting into my dream university in the UK. The resources and straightforward test format were excellent.",
    },
    {
      imgUrl: Default,
      name: "Nancy",
      designation: "Recent Graduate",
      about:
        "The SkillsTrainer ESOL test was flexible and quick, helping me land a job at an international firm.",
    },
    {
      imgUrl: Default,
      name: "Abhishek Challa",
      designation: "Educational Partner (University Admissions Officer)",
      about:
        "We rely on SkillsTrainer ESOL scores for their accurate sassessment of student English proficiency, ensuring readiness for academic success.",
    },
  ];
  const handleCoursePurchase = async (courseId) => {
    console.log("typee", courseId);
    window.open(`${url_webapp}/?action=purchase-course&courseId=${courseId}`);
  };

  return (
    <div className="App">
      <Container>
        {/* HERO SECTION */}
        <div className="row align-items-center">
          <div className="col-12 col-md-12 col-lg-6 col-xl-6 text-start mt-5">
            <h1 className="hero-heading">
              Unlock Global <br />
              Opportunities with <br />
              SkillsTrainer ESOL
            </h1>
            <div className="hero-para">
              Certify your English proficiency for
            </div>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                <button
                  onClick={() => handleCoursePurchase(1)}
                  className="btn international-edu"
                >
                  International Education <FaArrowRightLong />
                </button>
              </div>
              <div className="col-12 col-md-12 col-lg-12 col-xl-6 mt-2 mt-md-2 mt-lg-2 mt-xl-0">
                <button
                  onClick={() => handleCoursePurchase(2)}
                  className="btn international-emply"
                >
                  International Employment <FaArrowRightLong />
                </button>
              </div>
            </div>
            {/* <div className="py-4">Or</div> */}
            {/* <div className="row"> */}
            {/* <div className="col-12 col-md-12 col-lg-12 col-xl-6"> */}
            {/* <button className="btn register-work-partner">
              Register As Work Partner <FaArrowRightLong />
            </button> */}
            {/* </div> */}
            {/* </div> */}
          </div>
          <div className="col-12 col-md-12 col-lg-6 col-xl-6 col-xl-6 mt-4">
            <img src={hero} className="img-fluid" />
          </div>
        </div>
        <div className="gap"></div>
        {/* ABOUT US */}
        <section className="about">
          <h1 className="text-center common-heading">About ESOL</h1>
          <p className="text-center common-para pt-3 pb-3">
            SkillsTrainer ESOL International delivers NOCN ESOL International
            Qualifications across India and the SAARC Countries.
          </p>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 col-xl-1"></div>
            <div className="col-12 col-md-12 col-lg-12 col-xl-5 d-flex justify-content-center">
              <div className="card text-start background-color">
                <div className="card-body">
                  <img src={nocn} className="img-fluid" />
                  <div className="card-heading my-3">
                    English Language Competency
                  </div>
                  <div className="common-para">
                    SkillsTrainer ESOL, in collaboration with NOCN, offers
                    comprehensive English language testing across six CEFR
                    levels from A1 to C2. Successful candidates will receive an
                    internationally recognised qualification certificate.
                  </div>
                  <br />
                  <a className="fw-bold" href="#prepare-section">
                    Know More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 col-xl-5 d-flex justify-content-center mt-5 mt-md-5 mt-md-5 mt-lg-5 mt-xl-0">
              <div className="card text-start background-color">
                <div className="card-body">
                  <img src={globe} className="img-fluid" />
                  <div className="card-heading my-3">Regulation</div>
                  <div className="common-para">
                    NOCN ESOL International Qualifications are regulated by
                    Ofqual. It is widely accepted not only in the UK but also by
                    universities & employers in GCC countries, Africa, South
                    East Asia, the USA, Australia, New Zealand & Europe.
                  </div>
                  <br />
                  <a
                    target="_blank"
                    className="fw-bold"
                    href="https://www.nocn.org.uk/products/qualifications/esol-international/"
                  >
                    Know More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 col-xl-1"></div>
          </div>
        </section>
        <div className="gap"></div>
      </Container>

      {/* TEST OFFERING */}
      <section className="about test-offering custom-container pb-4">
        <Container>
          <div className="row align-items-center">
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="card text-start bg-transparent border-0">
                <div className="card-body">
                  <div className="card-heading py-4">
                    Choose The Right Qualification
                  </div>
                  <div className="card">
                    <div className="card-body p-3">
                      <div
                        className="success-learner-heading"
                        style={{ color: "#004A7C" }}
                      >
                        Levels A1 and A2
                      </div>
                      <div className="common-para">
                        Focus on basic communication and everyday situations.
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="card">
                    <div className="card-body p-3">
                      <div
                        className="success-learner-heading"
                        style={{ color: "#0D81BF" }}
                      >
                        Levels B1 and B2
                      </div>
                      <div className="common-para">
                        Intermediate skills, suitable for more complex
                        communication needs.
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="card">
                    <div className="card-body p-3">
                      <div
                        className="success-learner-heading"
                        style={{ color: "#EB0288" }}
                      >
                        Levels C1 and C2
                      </div>
                      <div className="common-para">
                        Advanced and proficient levels, preparing you for
                        academic and professional environments.
                      </div>
                    </div>
                  </div>
                  {/* <div className="common-para pt-3 pb-3">
                    Choose Your Path with SkillsTrainer ESOL
                  </div>
                  <div class="divider my-3"></div>
                  <div className="small-heading pb-2">
                    Test for International Employment
                  </div>
                  <div className="common-para pb-4">
                    Focus on practical language skills required in the
                    workplace.
                  </div>
                  <button className="btn register-work-partner mt-1">
                    Register Now for International Employment{" "}
                    <FaArrowRightLong />
                  </button>
                  <div className="small-heading pb-2 pt-4">
                    Test for International Education
                  </div>
                  <div className="common-para pb-2">
                    Tailored for academic success in English-speaking
                    environments.
                  </div>
                  <button className="btn register-work-partner">
                    Register Now for International Education{" "}
                    <FaArrowRightLong />
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="card  bg-transparent border-0">
                <div className="card-body">
                  <img src={testOfferings} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <div className="gap"></div>
      {/* Why Choose Skillstrainer ESOL? */}
      <Container>
        <section className="">
          <h1 className="text-center common-heading pb-3">
            Why Choose SkillsTrainer ESOL?
          </h1>
          <div className="why-choose-skillstrainer position-relative">
            <div
              className="row p-5 position-absolute"
              style={{ bottom: "60px", left: "60px" }}
            >
              <div className="col-md-4 d-flex align-items-stretch">
                <div className="card d-flex mt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-2">
                        <img className="img-fluid" src={trick} />
                      </div>
                      <div className="col-10 common-para text-start">
                        Accepted by over 5,000 institutions worldwide.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex align-items-stretch">
                <div className="card mt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-2">
                        <img className="img-fluid" src={trick} />
                      </div>
                      <div className="col-10 common-para text-start">
                        Fast and reliable results with certificates available
                        online.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex align-items-stretch">
                <div className="card mt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-2">
                        <img className="img-fluid" src={trick} />
                      </div>
                      <div className="col-10 common-para text-start">
                        Flexible options for online-based tests.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <img src={whyChoose} className="img-fluid" /> */}
        </section>
        <div className="gap"></div>
        {/* Prepare for Success */}
        <section className="success" id="prepare-section">
          <div className="row pt-3">
            {/* <div className="col-12 col-md-12 col-lg-12 col-xl-1"></div> */}
            <div className="col-12 col-md-12 col-lg-12 col-xl-6 d-flex justify-content-center">
              <img src={success} className="img-fluid" />
            </div>
            <div className="col-12 col-md-12 col-lg-12 col-xl-6 d-flex justify-content-center mt-5 mt-md-5 mt-md-5 mt-lg-5 mt-xl-0">
              <div className="card text-start bg-transparent border-0">
                <div className="card-body">
                  <h1 className="common-heading">Prepare for Success</h1>
                  <div className="card-heading my-3">
                    Check out our resources to prepare for successful ESOL
                    Certification!
                  </div>

                  <div class="divider mt-4 mb-3"></div>

                  <div className="row">
                    <div className="col-4">
                      <a href="#">
                        <span className="me-2"> Level A1 Practice</span>
                        <img className="img-fluid" src={rightArrow} />
                      </a>
                    </div>
                    <div className="col-4">
                      <a href="#">
                        <span className="me-2">Level B1 Practice</span>
                        <img className="img-fluid" src={rightArrow} />
                      </a>
                    </div>
                    <div className="col-4">
                      <a href="#">
                        <span className="me-2">Test Your CEFR Level</span>
                        <img className="img-fluid" src={rightArrow} />
                      </a>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-4">
                      <a href="#">
                        <span className="me-2">Level A2 Practice</span>
                        <img className="img-fluid" src={rightArrow} />
                      </a>
                    </div>
                    <div className="col-4">
                      <a href="#">
                        <span className="me-2">Level C1 Practice</span>
                        <img className="img-fluid" src={rightArrow} />
                      </a>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-4">
                      <a href="#">
                        <span className="me-2">Level B1 Practice</span>
                        <img className="img-fluid" src={rightArrow} />
                      </a>
                    </div>
                    <div className="col-4">
                      <a href="#">
                        <span className="me-2">Level C2 Practice</span>
                        <img className="img-fluid" src={rightArrow} />
                      </a>
                    </div>
                  </div>

                  {/* <button className="card-heading my-3 practice-tests mr-4 bg-white">
                    <img src={tickPink} className="img-fluid" /> Practice Tests
                  </button>
                  <button
                    className="card-heading my-3 practice-tests bg-white"
                    style={{ marginLeft: "5px" }}
                  >
                    <img src={tickPink} className="img-fluid" /> Study Guides
                  </button>
                  <div className="common-para my-3">
                    Start your preparation today and increase your chances of
                    success.
                  </div>
                  <button className="btn register-work-partner">
                    Explore Resources <FaArrowRightLong />
                  </button> */}
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-12 col-lg-12 col-xl-1"></div> */}
          </div>
        </section>
        <div className="gap"></div>
      </Container>
      {/* SUCCESS STORY */}
      <section className="text-center successfull-learners test-offering p-4 custom-container">
        <Container>
          <p className="common-para pt-5 pb-3">
            Don't just take our word for it!
          </p>
          <h1 className="common-heading pb-5">
            Hear from Our Successful Learners
          </h1>
          <Carousel
            responsive={responsive}
            arrows={false}
            showDots={false}
            infinite={true}
            autoPlay={true}
          >
            {carousalSuccess.map((data) => {
              return (
                <div>
                  <div className="">
                    <div className="">
                      <img src={data.imgUrl} className="img-fluid" />
                    </div>
                  </div>
                  <div className="success-learner-heading mt-3">
                    {data.name}
                  </div>
                  <div className="success-learner-designation mb-3">
                    {data.designation}
                  </div>
                  <div className="common-para">{data.about}</div>
                </div>
              );
            })}
          </Carousel>
          <div className="mt-3"></div>
        </Container>
      </section>

      <div className="gap"></div>
      {/* FREQUENTLY ASKED QUESTIONS */}
      <section className="frequently-asked">
        <Container>
          <div className="row align-items-center">
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="card  bg-transparent border-0">
                <div className="card-body">
                  <img src={successfulLearner} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="card text-start bg-transparent border-0">
                <div className="card-body">
                  <h1 className="pb-3">Frequently Asked Questions</h1>
                  {questionAnswer.map((data, index) => {
                    const isVisible = visibleAnswers[index];
                    return (
                      <div key={index}>
                        <div className="row">
                          <div className="col-10">
                            <div className="small-heading pb-2">
                              {data.question}
                            </div>
                            {isVisible && (
                              <div className="common-para pb-4">
                                {data.answer}
                              </div>
                            )}
                          </div>
                          <div className="col-2">
                            <button
                              onClick={() => toggleAnswerVisibility(index)}
                              style={{
                                background: "none",
                                border: "none",
                                fontSize: "1.5rem",
                                cursor: "pointer",
                              }}
                            >
                              {isVisible ? "-" : "+"}
                            </button>
                          </div>
                        </div>
                        <div className="divider my-3"></div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <div className="gap"></div>
      {/* Embark on Your Path to Global Opportunities */}
      <section className="opportunities">
        <Container>
          <div className="global-opportunities text-center">
            <h1 className="pb-3">
              Embark on Your Path to Global Opportunities
            </h1>
            <div className="small-heading py-3 mb-3">
              Certify your english proficiency for
            </div>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-xl-2"></div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                <button
                  onClick={() => handleCoursePurchase(1)}
                  className="btn international-edu"
                >
                  International Education <FaArrowRightLong />
                </button>
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-4 mt-2 mt-md-0 mt-lg-0 mt-xl-0">
                <button
                  className="btn international-emply"
                  onClick={() => handleCoursePurchase(2)}
                >
                  International Employment <FaArrowRightLong />
                </button>
              </div>
              <div className="col-12 col-md-12 col-lg-12 col-xl-2"></div>
            </div>
            <div className="py-4">Or</div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                <a href="/form">
                  <button className="btn register-work-partner">
                    Register As Work Partner <FaArrowRightLong />
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="gap"></div>

          <section className="social-media-post text-center">
            <h1 className="common-heading pb-5 ">Our Social media posts</h1>
            <InstagramPosts />
          </section>
          <div className="gap"></div>

          <section className="news">
            <h1 className="common-heading pb-5 text-center">News</h1>
            <Container>
              <div className="row">
                <div className="col-md-4">
                  <img className="img-fluid" src={news1} />
                  <div
                    className="text-start p-3"
                    style={{
                      background: "#F1F7FB",
                    }}
                  >
                    <div className="small-heading py-2">
                      NOCN forges partnership
                    </div>
                    <div className="pb-2">
                      NOCN has forged a partnership with Unifiers Social
                      Ventures
                    </div>
                    <a
                      target="_blank"
                      href="https://www.nocn.org.uk/news/nocn-forges-partnership-to-deliver-esol-international-qualifications-in-india.asp"
                    >
                      <span className="me-2">Know More</span>
                      <img className="img-fluid" src={rightArrow} />
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <img className="img-fluid" src={news2} />
                  <div
                    className="text-start p-3"
                    style={{
                      background: "#F1F7FB",
                    }}
                  >
                    <div className="small-heading py-2">
                      ESOL International from NOCN{" "}
                    </div>
                    <div className="pb-2">
                      International education and skills charity NOCN
                    </div>
                    <a
                      target="_blank"
                      href="https://www.nocn.org.uk/news/esol-international-from-nocn-in-partnership-with-unifiers-india.asp"
                    >
                      <span className="me-2">Know More</span>
                      <img className="img-fluid" src={rightArrow} />
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <img className="img-fluid" src={news3} />
                  <div
                    className="text-start p-3"
                    style={{
                      background: "#F1F7FB",
                    }}
                  >
                    <div className="small-heading py-2">Inside Higher</div>
                    <div className="pb-2">
                      Latest news, reports, and data for those in higher...
                    </div>
                    <a href="#">
                      <span className="me-2">Know More</span>
                      <img className="img-fluid" src={rightArrow} />
                    </a>
                  </div>
                </div>
              </div>
            </Container>
          </section>
        </Container>
      </section>

      <div className="gap"></div>
    </div>
  );
}

export default Index;
