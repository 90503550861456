import Container from "react-bootstrap/Container";
import { FaArrowRightLong } from "react-icons/fa6";
import Default from "../assests/successLearner.png";
import { window } from "browser-monads";
import rightArrow from "../assests/RightArrow.svg";
import news1 from "../assests/news1.png";
import news2 from "../assests/news2.png";
import news3 from "../assests/news3.png";
import flag from "../assests/flag.png";
import flower from "../assests/image.png";
import qualification from "../assests/qualification.png";
import globalResearch from "../assests/globalResearch.png";

function Accreditation() {
  return (
    <div className="App">
      {/* <Header /> */}

      <Container>
        <div className="img-bg">
          <div className="text-center row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <img src={flag} className="img-fluid" />
              <img src={flower} className="img-fluid ms-4" />
              <h3 className="mt-5 lh-base">
                SkillsTrainer ESOL International delivers NOCN ESOL
                International Qualifications in India & the SAARC Countries.
              </h3>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
        {/* HERO SECTION */}
        <div className="row align-items-center">
          <div className="col-12 col-md-12 col-lg-6 col-xl-6 text-start mt-5"></div>
        </div>
      </Container>

      <section className="about test-offering custom-container pb-4">
        <Container>
          <div className="row align-items-center">
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <h className="common-heading">
                NOCN International ESOL Qualifications
              </h>
              <p className="common-para mt-4">
                NOCN's world-leading, Ofqual-approved ESOL International
                qualifications are designed to support learners emigrating to or
                working in an English-speaking country, or those who wish to
                learn English as a new language.
              </p>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="card  bg-transparent border-0">
                <div className="card-body">
                  <img src={qualification} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <div className="gap"></div>

      <section className="about custom-container pb-4">
        <Container>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="card  bg-transparent border-0">
                <div className="card-body">
                  <img src={globalResearch} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <h className="font-size-40 ">
                Global Reach of Ofqual Certifications
              </h>
              <p className="common-para mt-2">
                Ofqual, the Office of Qualifications and Examinations
                Regulation, is the government body responsible for regulating
                qualifications, exams, and assessments in England. Ofqual
                certifications are widely accepted not only in the UK but also
                by universities and employers in GCC countries, Africa, South
                East Asia, the USA, Australia, New Zealand, and Europe. This
                global recognition is a testament to Ofqual's stringent
                regulatory framework, which ensures that qualifications meet a
                consistent standard of quality and reliability. For Indian
                students, earning an Ofqual-certified qualification opens doors
                to educational and career opportunities in these regions, making
                it an invaluable asset for those aiming to study or work
                internationally. The broad acceptance of an Ofqual certification
                provides Indian students with a strong foundation to compete and
                succeed on a global stage.
              </p>
            </div>
          </div>
        </Container>
      </section>
      {/* Embark on Your Path to Global Opportunities */}
      <section className="opportunities text-center">
        <Container>
          <div className="global-opportunities">
            <h1 className="pb-3">
              To learn more about NOCN ESOL <br />
              International Qualifications
            </h1>

            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                <button className="btn register-work-partner">
                  Learn More <FaArrowRightLong />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <div className="gap"></div>
    </div>
  );
}

export default Accreditation;
