import React, { useRef } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";

const Form = () => {
  const formRef = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(formRef.current);

    try {
      const response = await axios.post(
        "https://script.google.com/macros/s/AKfycbwMwfPZycextxzXCBH5AtQhQ_wCy3aSMZcfZWR4FubHzNqEnQWUShKgQYIgYOJ8OgJzVw/exec",
        formData
      );

      console.log("response", response);

      if (response.data.result === "success") {
        alert("Form submitted successfully");
        formRef.current.reset();
      } else {
        alert("Failed to submit form");
      }
    } catch (error) {
      console.error("Error submitting form", error);
      alert("Error submitting form");
    }
  };

  return (
    <Container>
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="row mt-5">
          <div className="col-md-6 mt-4">
            <label htmlFor="first-name">First Name:</label>
            <input
              className="form-control"
              type="text"
              id="first-name"
              name="first-name"
              required
            />
          </div>
          <div className="col-md-6 mt-4">
            <label htmlFor="surname">Surname:</label>
            <input
              className="form-control"
              type="text"
              id="surname"
              name="surname"
              required
            />
          </div>
          <div className="col-md-6 mt-4">
            <label htmlFor="organisation">Organisation:</label>
            <input
              className="form-control"
              type="text"
              id="organisation"
              name="organisation"
              required
            />
          </div>
          <div className="col-md-6 mt-4">
            <label htmlFor="job-title">Job title:</label>
            <input
              className="form-control"
              type="text"
              id="job-title"
              name="job-title"
              required
            />
          </div>
          <div className="col-md-6 mt-4">
            <label htmlFor="region">Region:</label>
            <input
              className="form-control"
              type="text"
              id="region"
              name="region"
              required
            />
          </div>
          <div className="col-md-6 mt-4">
            <label htmlFor="email">Contact Email:</label>
            <input
              className="form-control"
              type="email"
              id="email"
              name="email"
              required
            />
          </div>
          <div className="col-md-6 mt-4">
            <label htmlFor="number">Phone number:</label>
            <input
              className="form-control"
              type="number"
              id="number"
              name="number"
              required
            />
          </div>
          <div className="col-md-6 mt-4">
            <label htmlFor="scope-of-involment">Scope of Involvement:</label>
            <textarea
              className="form-control"
              type="text"
              id="scope-of-involment"
              name="scope-of-involment"
              required
            />
          </div>
        </div>
        <button type="submit" className="btn register-work-partner mt-4">
          Submit
        </button>
        <div className="gap"></div>
      </form>
    </Container>
  );
};

export default Form;
