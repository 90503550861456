import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from "./pages/index";
import Form from "./pages/form";
import Accreditation from "./pages/accreditation";
import Header from "./components/headers";
import Footer from "./components/footer";
import Partners from "./pages/partners";
const App = () => {
  return (
    <Router>
      <Header />
      <div className="content">
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/accreditation" element={<Accreditation />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/form" element={<Form />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
