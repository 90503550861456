export const questionAnswer = [
  {
    question: "What is SkillsTrainer ESOL?",
    answer:
      "SkillsTrainer ESOL offers English language proficiency tests designed to certify students and professionals for international education and employment. Our tests are aligned with the Common European Framework of Reference for Languages (CEFR) and are accepted by thousands of institutions worldwide.",
  },
  {
    question: "Who can take the SkillsTrainer ESOL tests?",
    answer: "",
  },
  {
    question:
      "What levels of proficiency do the SkillsTrainer ESOL tests cover?",
    answer: "",
  },
  {
    question: "How do I register for a SkillsTrainer ESOL test?",
    answer: "",
  },
  {
    question: "Where are the SkillsTrainer ESOL tests accepted?",
    answer: "",
  },
  {
    question: "What is the format of the SkillsTrainer ESOL tests?",
    answer: "",
  },
];
