import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assests/esolLogo.png";
import { IoMdHome } from "react-icons/io";
import { HiSquare3Stack3D } from "react-icons/hi2";
import { FaHandshakeSimple } from "react-icons/fa6";
function Header() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary navbar p-3">
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} className="img-fluid" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="">
          <Nav className="">
            <Nav.Link href="/">
              <IoMdHome /> Home
            </Nav.Link>
            <Nav.Link href={`/accreditation`}>
              <HiSquare3Stack3D /> Resources
            </Nav.Link>
            <Nav.Link href="/partners">
              <FaHandshakeSimple /> Work Partners
            </Nav.Link>
            <Nav.Link href="/accreditation">
              <FaHandshakeSimple /> Accreditation
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end download-brochure">
          <a
            target="_blank"
            href={
              "https://esol-brochure.s3.ap-south-1.amazonaws.com/ESOL_Brochure.6fda75ef2df63a080148+(1).pdf"
            }
            className="btn download"
            download
          >
            Download Brochure
          </a>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
