import Container from "react-bootstrap/Container";
import MFSDAE from "../assests/MFSDAE.png";
import UNDP from "../assests/UNDP.png";
import NSDC from "../assests/NSDC.png";
import iitd_logo from "../assests/iitd_logo.png";

import mepsc_logo from "../assests/mepsc_logo.png";
import RASCI from "../assests/RASCI.png";
import BWSSC from "../assests/BWSSC.jpg";
import TSSC_PNG from "../assests/TSSC_PNG.png";
import asdc from "../assests/asdc.png";
import SCGJ from "../assests/SCGJ.jpg";

import Collins_Aerospace from "../assests/Collins_Aerospace.png";
import G4Slogo from "../assests/G4Slogo.png";
import Honda from "../assests/Honda.png";
import HeroLogo from "../assests/Hero.png";
import Britannia from "../assests/Britannia.png";
import Airtel from "../assests/Airtel.png";
import essi from "../assests/essi.png";
import logo from "../assests/blueEsol.png";
import nocn from "../assests/nocn.png";
import governmentInstitutional from "../assests/governmentInstitutional.png";
import sectorSkill from "../assests/sectorSkill.png";
import corporate from "../assests/corporate.png";
function Partners() {
  return (
    <div className="App">
      <Container>
        <div className="partners-bg">
          <div className="text-center row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <h3 className="common-heading" style={{ display: "contents" }}>
                Work Partners
              </h3>
              <div className="d-inline-flex pt-4">
                <img src={logo} className="img-fluid" />
                <img src={nocn} className="img-fluid ms-4" />
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>

        <div className="text-center">
          <div className="mt-4">
            <h2 className="common-heading mb-4">
              Government & Institutional Partners
            </h2>

            <img className="img-fluid" src={governmentInstitutional} />
          </div>

          <div className="gap"></div>

          <h2 className="common-heading mb-4">Sector Skill Councils</h2>
          <img className="img-fluid" src={sectorSkill} />

          <div className="gap"></div>

          <h2 className="common-heading mb-4">Corporate</h2>
          <img className="img-fluid" src={corporate} />
        </div>
      </Container>
      <div className="gap"></div>
    </div>
  );
}

export default Partners;
