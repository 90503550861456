import React, { useEffect, useState } from "react";
import { insta_token } from "../config";
const InstagramPosts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch(
      `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${insta_token}`
    )
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.data
          .filter((item) => item.caption.toLowerCase().includes("esol"))
          .slice(0, 3);
        setPosts(filteredData);
      })
      .catch((error) =>
        console.error("Error fetching Instagram posts:", error)
      );
  }, []);

  return (
    <div className="container">
      <div className="row">
        {posts.map((post) => {
          return (
            <div key={post.id} className="col-md-3 mb-4">
              <a
                href={post.permalink}
                target="_blank"
                rel="noopener noreferrer"
                className="d-block"
              >
                {post.media_type === "IMAGE" && (
                  <img
                    className="img-fluid w-100"
                    style={{
                      height: "300px",
                      objectFit: "cover",
                      width: "100%",
                    }}
                    src={post.media_url}
                    alt={post.caption}
                  />
                )}
                {post.media_type === "VIDEO" && (
                  <div className="embed-responsive embed-responsive-1by1">
                    <video
                      className="embed-responsive-item"
                      controls
                      style={{
                        width: "100%",
                        height: "300px",
                        objectFit: "cover",
                      }}
                      src={post.media_url}
                    />
                  </div>
                )}
                {post.media_type === "CAROUSEL_ALBUM" && (
                  <img
                    className="img-fluid w-100"
                    style={{ height: "300px", objectFit: "cover" }}
                    src={post.media_url}
                    alt={post.caption}
                  />
                )}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InstagramPosts;
